'use strict';

function webFonts () {

    var css_href = '/css_static/web-fonts.css?m=15';

    function on(el, ev, callback) {

        if ( el.addEventListener ) {

            el.addEventListener(ev, callback, false);

        } else if (el.attachEvent) {

            el.attachEvent("on" + ev, callback);

        }

    }

    injectFontsStylesheet();

    function fileIsCached(href) {

        return window.localStorage && localStorage.font_css_cache && (localStorage.font_css_cache_file === href);

    }


    function injectFontsStylesheet() {

        if (!window.localStorage || !window.XMLHttpRequest) {

            var stylesheet = document.createElement('link');
            stylesheet.href = css_href;
            stylesheet.rel = 'stylesheet';
            stylesheet.type = 'text/css';
            document.getElementsByTagName('head')[0].appendChild(stylesheet);

            document.cookie = "font_css_cache";


        } else {

            if (fileIsCached(css_href)) {

                injectRawStyle(localStorage.font_css_cache);

            } else {

                var xhr = new XMLHttpRequest();
                xhr.open("GET", css_href, true);
                on(xhr, 'load', function () {
                    if (xhr.readyState === 4) {

                        injectRawStyle(xhr.responseText);

                        localStorage.font_css_cache = xhr.responseText;
                        localStorage.font_css_cache_file = css_href;
                    }
                });
                xhr.send();
            }
        }

    }


    function injectRawStyle(text) {

        if ( !(/MSIE 8\.0|MSIE 7\.0|MSIE 6\.0/).test(navigator.userAgent) ) {

            var style = document.createElement('style');

            style.innerHTML = text;

            document.getElementsByTagName('head')[0].appendChild(style);

        }

    }

}

// Including web fonts
try {

    webFonts();

} catch ( error ) {

    localStorage.clear();
    webFonts();

}